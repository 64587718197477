import { useSessionStorage } from "@mantine/hooks";
import dynamic               from "next/dynamic";
import { useRouter }         from "next/router";
import React, { useEffect }  from "react";

import { useGetLoggedIn }   from "_common/hooks/auth";
import { useGetLoginPopup } from "_common/hooks/global";
import Render               from "_components/atoms/render";

const UserOnboarding = dynamic ( () => import ( "./components/userOnboarding" ) );
const PIPPlayer      = dynamic ( () => import ( "./components/pip" ) );
const DownloadPopup  = dynamic ( () => import ( "./components/downloadPopup" ) );
const UpdateProfile  = dynamic ( () => import ( "./components/updateProfile" ) );
const CaptureUser    = dynamic ( () => import ( "./components/captureUser" ) );
const ToggleSidebar  = dynamic ( () => import ( "./components/toggleSidebar" ) );

const RTCWebSocketProvider = dynamic ( () => import ( "_components/features/rtcSocket" ) );
const AdBlockerPopup       = dynamic ( () => import ( "_components/organisms/adBlockerPopup" ) );
const LoginContainer       = dynamic ( () => import ( "_components/pages/auth" ) );
const ProStatusModal       = dynamic ( () => import ( "_components/organisms/proStatusModal" ) );
const ProPage              = dynamic ( () => import ( "_components/pages/proPage" ) );
const WalletPage           = dynamic ( () => import ( "_components/pages/wallet/landingPage" ) );

const GlobalWrapper = ( { load, children, isWebview, isMobile, userAuth } ) => {
	const loginPopup        = useGetLoginPopup ();
	const isLoggedInUser    = useGetLoggedIn ();
	const router            = useRouter ();
	const nowProQuery       = router.query.nowPro;
	const proCancelledQuery = router.query.proCancelled;
	const proUpgradedQuery  = router.query.proUpgraded;
	const proModalQuery     = !!router.query.proModal;
	const alreadyProQuery   = !!router.query.alreadyPro;
	const walletQuery       = router.query.wallet;

	const [ walletModalState, setWalletModalState ] = useSessionStorage ( { key: "feat-wallet-modal" } );

	const param = nowProQuery ? "nowPro" : proCancelledQuery ? "proCancelled" : proUpgradedQuery ? "proUpgraded" : null;

	useEffect ( () => {
		if ( !walletQuery )
			return;

		setWalletModalState ( {
			opened: true
		} );
	}, [ walletQuery ] );

	if ( !load )
		return children;

	return (
		<React.Fragment>
			<Render condition = { !isWebview && !isMobile }>
				<PIPPlayer />

				<UserOnboarding />
			</Render>

			<Render condition = { !isWebview }>
				<LoginContainer showLoginDialog = { loginPopup } />

				<Render condition = { !!param }>
					<ProStatusModal param = { param } />
				</Render>

				<AdBlockerPopup />
			</Render>

			<Render condition = { isMobile && !isWebview }>
				<DownloadPopup />
			</Render>

			<Render condition = { isWebview || isLoggedInUser }>
				<UpdateProfile
					isWebview = { isWebview }
					userId    = { userAuth?.userId }
				/>
			</Render>

			<Render condition = { !isWebview && isLoggedInUser }>
				<CaptureUser />
			</Render>

			<ToggleSidebar />

			<Render condition = { !isMobile && ( proModalQuery || alreadyProQuery ) }>
				<ProPage initalOpen = { false } />
			</Render>

			<Render condition = { !isMobile && walletModalState?.opened }>
				<WalletPage
					CSR              = { true }
					updateModalState = { setWalletModalState }
				/>
			</Render>

			<RTCWebSocketProvider isWebview = { isWebview }>
				{children}
			</RTCWebSocketProvider>

		</React.Fragment>
	);
};

export default GlobalWrapper;
